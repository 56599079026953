<template>
    <div>
        <form class="form"
            novalidate="novalidate"
            id="st_tax_category_form"
        >
            <div class="form-group">
                <label>
                    {{ fields.name.label }} *
                </label>
                <st-input-text
                    :ref="fields.name.name"
                    :name="fields.name.name"
                    v-model="model[fields.name.name]"
                />
            </div>
            <div class="form-group">
                <label>
                    {{ fields.status.label }} *
                </label>
                <b-form-select
                    v-model="model[fields.status.name]"
                    :options="fields.status.options"
                    :ref="fields.status.name"
                    :name="fields.status.name"
                >
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ $t('GENERAL.SELECT_PLACEHOLDER') }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </div>

            <div class="pt-6">
                <slot name="buttons" :submit="submit" :cancel="cancel"/>
            </div>
        </form>
    </div>
</template>

<script>
import {FormSchema} from '@/shared/form/form-schema';
import {TaxCategoryModel} from '@/modules/tax-category/tax-category-model';
import { createFormValidation } from '@/shared/utils/create-form-validation';

const {fields} = TaxCategoryModel;

const formSchema = new FormSchema([
    fields.name,
    fields.status
]);

export default {
    name: 'TaxCategoryForm',
    props: {
        item: {
            type: Object,
            default: () => ({ }),
        }
    },
    data() {
        return {
            model: formSchema.initialValues(this.item),
            fields,
            rules: formSchema.rules(),
        };
    },
    mounted() {
        this.fv = createFormValidation('st_tax_category_form', this.rules);
    },
    methods: {
        async submit() {
            const validate = await this.fv.validate();
            if (validate === 'Valid') {
                const cast = formSchema.cast(this.model);
                this.$emit('submit', cast);
            }
        },
        cancel() {
            this.$emit('cancel');
        },
    },
};
</script>

