<template>
    <st-filters-pagination
        stateModule="taxCategory/list"
        :filters="filters"
        :total="total"
        @change="onChange"
        :header="$t('TAX_CATEGORY.FILTER.TITLE')"
        :moreFilters="false"
    >
        <slot></slot>
    </st-filters-pagination>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { FilterSchema } from '@/shared/form/filter-schema';
    import { TaxCategoryModel } from '@/modules/tax-category/tax-category-model';

    const { fields } = TaxCategoryModel;

    const filterSchema = new FilterSchema([
        fields.name,
        fields.status,
    ]);

    export default {
        name: 'TaxCategoryListFilter',
        data() {
            return {
                filters: filterSchema.fields,
                currentPage: 1,
            };
        },
        computed: {
            ...mapGetters({
               total: 'taxCategory/list/total',
            }),
        },
        methods: {
            onChange(params) {
                this.$emit('params', params);
            },
        },
    };
</script>