<template>
    <div>
        <st-data-table
            :items="rows"
            :fields="fields"
            :actions="actions"
            :modelPresenter="presenter"
            @edit="onEdit"
            @delete="onDelete"
            responsive="sm"
            hover
        >
        </st-data-table>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { TaxCategoryModel } from '@/modules/tax-category/tax-category-model';

    const { fields } = TaxCategoryModel;

    export default {
        name: 'TaxCategoryListTable',
        data() {
            return {
                presenter: TaxCategoryModel.presenter,
                fields: [
                    fields.name,
                    fields.status.extendField({ labelType: true }),
                ],
            };
        },
        computed: {
            ...mapGetters({
                rows: 'taxCategory/list/rows',
                taxCategoryPermissions: 'taxCategory/list/taxCategoryPermissions',
            }),
            actions() {
                return [
                    {
                        name: 'edit',
                        icon: 'edit',
                        tooltipText: this.$t('GENERAL.ACTIONS.EDIT'),
                        customIcon: false,
                        type: 'primary',
                        permission: this.taxCategoryPermissions.hasPermissionToEdit
                    },
                    {
                        name: 'delete',
                        icon: 'trash',
                        tooltipText: this.$t('GENERAL.ACTIONS.DELETE'),
                        customIcon: false,
                        type: 'primary',
                        permission: this.taxCategoryPermissions.hasPermissionToDestroy
                    }
                ]
            }
        },
        methods: {
            onEdit(item) {
                this.$emit('edit', item.item);
            },
            onDelete(item) {
                this.$emit('delete', item.item);
            },
        },
    };
</script>
