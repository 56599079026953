import GenericModel from '@/shared/models/generic-model';
import i18n from '@/shared/plugins/vue-i18n';
import StringField from '@/shared/fields/string-field';
import EnumField from '@/shared/fields/enum-field';
import IdField from '@/shared/fields/id-field';

const label = (name) => i18n.t(`TAX_CATEGORY.FIELDS.${name.toUpperCase()}`);
const enumLabel = (name, value) => i18n.t(`TAX_CATEGORY.ENUMS.${name.toUpperCase()}.${value.toUpperCase()}`);
const placeholder = (name) => i18n.t(`TAX_CATEGORY.PLACEHOLDERS.${name.toUpperCase()}`);

const fields = {
    id: new IdField('id', 'Id'),
    name: new StringField('name', label('name'), {
        required: true,
    }, { placeholder: placeholder('name') }),

    status: new EnumField('status', label('status'), [
        { value: 'active', text: enumLabel('status', 'active'), type: 'primary', name: 'active' },
        { value: 'inactive', text: enumLabel('status', 'inactive'), type: 'danger' , name: 'inactive' }
    ], {
        required: true
    }, { placeholder: placeholder('status') }),
};

export class TaxCategoryModel extends GenericModel {
    static get fields() {
        return fields;
    }
}
